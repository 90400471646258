@mixin triangle($size, $color, $direction) {
    $width: nth($size, 1);
    $height: nth($size, length($size));
    $foreground-color: nth($color, 1);
    $background-color: if(length($color) == 2, nth($color, 2), transparent);
    height: 0;
    width: 0;

    @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left)
    {
        $width: $width / 2;
        $height: if(length($size) > 1, $height, $height/2);

        @if $direction == up {
            border-bottom: $height solid $foreground-color;
            border-left: $width solid $background-color;
            border-right: $width solid $background-color;
        } @else if $direction == right {
            border-bottom: $width solid $background-color;
            border-left: $height solid $foreground-color;
            border-top: $width solid $background-color;
        } @else if $direction == down {
            border-left: $width solid $background-color;
            border-right: $width solid $background-color;
            border-top: $height solid $foreground-color;
        } @else if $direction == left {
            border-bottom: $width solid $background-color;
            border-right: $height solid $foreground-color;
            border-top: $width solid $background-color;
        }
    } @else if ($direction == up-right) or ($direction == up-left) {
        border-top: $height solid $foreground-color;

        @if $direction == up-right {
            border-left: $width solid $background-color;
        } @else if $direction == up-left {
            border-right: $width solid $background-color;
        }
    } @else if ($direction == down-right) or ($direction == down-left) {
        border-bottom: $height solid $foreground-color;

        @if $direction == down-right {
            border-left: $width solid $background-color;
        } @else if $direction == down-left {
            border-right: $width solid $background-color;
        }
    } @else if ($direction == inset-up) {
        border-color: $background-color $background-color $foreground-color;
        border-style: solid;
        border-width: $height $width;
    } @else if ($direction == inset-down) {
        border-color: $foreground-color $background-color $background-color;
        border-style: solid;
        border-width: $height $width;
    } @else if ($direction == inset-right) {
        border-color: $background-color $background-color $background-color $foreground-color;
        border-style: solid;
        border-width: $width $height;
    } @else if ($direction == inset-left) {
        border-color: $background-color $foreground-color $background-color $background-color;
        border-style: solid;
        border-width: $width $height;
    }
}
